.reservationSummary {
    background-color: #FFF3D9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    margin: 20px auto;
  }
  
  .summaryServiceTitle {
    font-size:20px;
    font-weight: 400;
    span {
        font-weight: bold;
    }
  }

  .summaryTitle {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
  }
  
  .summaryItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .itemTitle {
    color: #555;
  }
  
  .itemValue {
    color: #333;
    font-weight: 600;
  }
  
  .originalPrice {
    text-decoration: line-through;
    color: #888;
  }
  
  .promoPrice {
    color: #ff5733;
  }
  
  .totalTitle {
    font-size: 2em;
  }
  
  .totalPrice {
    font-size: 2em;
    font-weight: 700;
  }
  
  .discount {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    background-color: #e6f7ff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .discountLabel {
    font-weight: bold;
    color: #007bff;
  }
  
  .discountValue {
    color: #007bff;
  }
.extra_service_active {
    display: grid;
    grid-template-columns: 70% 30%;
    .name {
        font-weight:600;
    }
    .price {
        font-weight: 600;
        justify-self: end;
        align-self: center;
    }
}
.brown {
    color: brown;
}