//TIPOGRAFIA

/* FONTS */
/* latin-ext */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
        url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRdu3cOWxy40.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
        url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu3cOWxw.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
        url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lujVj9_mf.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
        url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
        url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmRdu3cOWxy40.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
        U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
        url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu3cOWxw.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font-family: "Source Sans Pro", sans-serif;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;

//COLORES
// colores principales segun la identidad corporativa del negocio:
$accent-color-1: #000000;
$accent-color-2: #fa5454;
$accent-color-3: #404042;

$color-special-1: #f2ca80;

$lightgrey: #f9f9f9;
$border-light: #efefef;

$gray: #2e2e2e;
$elegant-color-dark: #212121;
$success: #46be8a;
